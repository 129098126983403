.App {
    font-family: "Fjalla One", sans-serif;
    letter-spacing: 0.07rem;
}

h1 {
    letter-spacing: 0.1rem;
}

html,
body {
    background-color: #282c34;
}

.App-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding: 1em;
    box-sizing: border-box;
    background-color: #2e3645;
}

.App-logo {
    height: 2.5em;
    width: 2.5em;
    aspect-ratio: 1 / 1;
    background: #ffcb85;
    border-radius: 12px;
    margin-right: 0.8em;
}

.App-logoText {
    font-size: 1.4em;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    color: #fff;
}

.App-main {
    background-color: #282c34;
    padding: 1em;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-heading {
    margin-bottom: 0;
    font-size: 1em;
    text-align: center;
}

.App-depRow {
    margin-bottom: 0;
    margin-top: 1em;
    padding: 0.5em 1em;
    border-radius: 12px;
    width: 100%;
    border: 1px solid #3d4350;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3d4350;
}
.App-depRow-time {
    color: rgba(255, 255, 255, 0.5);
    display: inline-block;
    margin-bottom: 0.5em;
}
.App-depRow .App-depRow-departure {
    font-size: 0.7em;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.App-link {
    color: #61dafb;
}

.App-depTime {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8em;
}

.App-running-late {
    color: #ff8596;
    font-weight: bold;
    align-items: center;
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
}
.App-running-late img {
    margin-right: 5px;
}

.App-timeTableType {
    color: rgba(255, 255, 255, 0.5);
    margin-top: 1em;
    font-size: 0.9em;
    text-align: center;
    line-height: 1.6em;
}

.App-completeTimeTable {
    overflow: auto;
    display: flex;
    padding: 0.2em 0;
    align-items: center;
    scroll-snap-type: x mandatory;
}
.App-ctt-heading {
    font-size: 0.8em;
    font-weight: normal;
    margin: 1em 0 0.3em;
}
.App-timeTag {
    background-color: #3d4350;
    padding: 6px 12px;
    border-radius: 6px;
    scroll-snap-align: start;
    margin-right: 10px;
}

.App-github {
    color: rgba(255, 255, 255, 0.5);
}

.App-settings-heading {
    color: rgba(255, 255, 255, 0.5);
    margin: 0;
    margin-top: 2em;
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: inherit;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    padding: 5px 20px;
}
hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.App-settings-panel {
    padding: 0 1em 2em;
    font-size: 16px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.App-settings-panel h4 {
    color: #fff;
    text-align: center;
    margin-top: 0;
}
.App-settings-panel p {
    color: #fff;
}
.App-settings-mins {
    width: 100px;
}
.App-settings-section {
    margin-bottom: 1.8em;
}
.App-settings-radiogroup {
    margin-right: 1em;
}

label {
    color: #fff;
}
.form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
    margin-bottom: 1em;
}

input[type="number"] {
    font-family: "Fjalla One", sans-serif;
    border: 2px solid #282c34;
    border-radius: 4px;
    padding: 5px;
    background: #fff;
}

button {
    font-family: "Fjalla One", sans-serif;
    background-color: #282c34;
    color: #fff;
    padding: 10px 30px;
    border: none;
    border-radius: 50px;
}

.center {
    text-align: center;
}

.react-modal-sheet-backdrop {
    /* custom styles */
}
.react-modal-sheet-container {
    background-color: #3d4350 !important;
}
.react-modal-sheet-header {
    /* custom styles */
}
.react-modal-sheet-drag-indicator {
    /* custom styles */
}
.react-modal-sheet-content {
    /* custom styles */
}
